<template>
  <!-- Auction Tab Starts -->

  <div class="text-center" style="display: table; margin: 12px auto 0px">
    <ul class="nav nav-tabs" role="tablist">
      <!-- <li class="nav-Link filter-nav-Link" style="margin-right: 10px">
        <div class="filter-icon" @click="toggleFilter">
          <i v-if="!isFilterOpen" class="fas fa-filter"></i>
          <i v-else class="fas fa-times"></i>
        </div>
      </li> -->

      <li class="nav-item">
        <a class="nav-link active" data-toggle="tab" href="#tabs-1" role="tab">Live Auctions</a>
      </li>

      <li class="nav-item">
        <a class="nav-link" data-toggle="tab" href="#tabs-2" role="tab">Upcoming Auctions</a>
      </li>
    </ul>
    <!-- Tab panes -->
  </div>

  <div class="tab-content">
    <div class="tab-pane active" id="tabs-1" role="tabpanel">
      <div class="" style="padding: 40px 0px; background-color: white">
        <div class="container-fluid">
          <div v-if="!items.length">
            <NotFound></NotFound>
          </div>
          <div class="row" v-else>
            <div class="col-lg-2 col-md-5 order-1 order-lg-1 order-md-1">
              <div class="uren-sidebar-catagories_area" style="margin-top: 5px">
                <div class="uren-sidebar_categories">
                  <ul class="sidebar-checkbox_list">
                    <li>
                      <div class="clearAllFilter">
                        <h5>Filters</h5>
                        <a @click="ClearFilterdItemsList">Reset all</a>
                      </div>
                    </li>
                  </ul>
                </div>
                <div class="uren-sidebar_categories">
                  <div class="uren-categories_title">
                    <h5 @click="YearActive">
                      Year of Make
                      <span v-if="isYearActive"><i class="fa fa-chevron-down" aria-hidden="true"></i>
                      </span>
                      <span v-if="!isYearActive"><i class="fa fa-chevron-up" aria-hidden="true"></i>
                      </span>
                    </h5>
                  </div>

                  <div class="yearOfMakeClider" v-if="isYearActive">
                    <Slider v-model="yearOfMakeSlider.value" v-bind="yearOfMakeSlider" :min="yearOfMakeSlider.min"
                      :max="yearOfMakeSlider.max" @update="yearChange(yearOfMakeSlider)"></Slider>
                  </div>
                </div>
                <div class="uren-sidebar_categories">
                  <div class="uren-categories_title">
                    <h5 @click="KmActive">
                      KM
                      <span v-if="isKmActive"><i class="fa fa-chevron-down" aria-hidden="true"></i>
                      </span>
                      <span v-if="!isKmActive"><i class="fa fa-chevron-up" aria-hidden="true"></i>
                      </span>
                    </h5>
                  </div>

                  <div class="kmClockedSlider" v-if="isKmActive">
                    <Slider v-model="KmClockedSlider.value" v-bind="KmClockedSlider" :min="KmClockedSlider.min"
                      :max="KmClockedSlider.max" @update="kmClockedChange(KmClockedSlider)"></Slider>
                  </div>
                </div>

                <div class="uren-sidebar_categories">
                  <div class="uren-categories_title">
                    <h5 @click="BodyTypeActive">
                      Body Type
                      <span v-if="isBodyTypeActive"><i class="fa fa-chevron-down" aria-hidden="true"></i>
                      </span>
                      <span v-if="!isBodyTypeActive"><i class="fa fa-chevron-up" aria-hidden="true"></i>
                      </span>
                    </h5>
                  </div>
                  <input v-if="isBodyTypeActive" type="checkbox" id="isBodyTypeselectAll" class="isBodyTypeselectAll"
                    name="isBodyType" aria-label="All Body Type" @change="selectIndCheckBox('isBodyType')" />
                  <label for="" v-if="isBodyTypeActive">All Body Type</label>
                  <ul class="sidebar-checkbox_list" v-for="(TypeName, index) in uniqueVehType" :key="index">
                    <li v-if="isBodyTypeActive">
                      <input type="checkbox" name="chk" class="isBodyType" v-model="veTypeName"
                        :value="TypeName.vehTypeName" />

                      <span>{{ TypeName.vehTypeName }} </span>
                    </li>
                  </ul>
                </div>
                <div class="uren-sidebar_categories">
                  <div class="uren-categories_title">
                    <h5 @click="MakeActive">
                      Makes
                      <span v-if="isMakeActive"><i class="fa fa-chevron-down" aria-hidden="true"></i>
                      </span>
                      <span v-if="!isMakeActive"><i class="fa fa-chevron-up" aria-hidden="true"></i>
                      </span>
                    </h5>
                  </div>
                  <input v-if="isMakeActive" type="checkbox" id="isMakeselectAll" class="isMakeselectAll" name="isMake"
                    aria-label="Select All" @change="selectIndCheckBox('isMake')" />
                  <label for="" v-if="isMakeActive">All Makes </label>
                  <ul class="sidebar-checkbox_list" v-for="(brand, index) in uniqueBrand" :key="index">
                    <li v-if="isMakeActive">
                      <input type="checkbox" name="chk" class="isMake" v-model="veBrand" :value="brand.brandName" />
                      <span>{{ brand.brandName }} </span>
                    </li>
                  </ul>
                </div>

                <div class="uren-sidebar_categories">
                  <div class="uren-categories_title">
                    <h5 @click="ModelActive">
                      Model
                      <span v-if="isModelActive"><i class="fa fa-chevron-down" aria-hidden="true"></i>
                      </span>
                      <span v-if="!isModelActive"><i class="fa fa-chevron-up" aria-hidden="true"></i>
                      </span>
                    </h5>
                  </div>
                  <input v-if="isModelActive" type="checkbox" id="isModelselectAll" class="isModelselectAll"
                    name="isModel" aria-label="Select All" @change="selectIndCheckBox('isModel')" />
                  <label for="" v-if="isModelActive">All Models</label>
                  <ul class="sidebar-checkbox_list" v-for="(model, index) in uniqueModel" :key="index">
                    <li v-if="isModelActive">
                      <input type="checkbox" name="chk" class="isModel" v-model="veModel" :value="model.modelName" />
                      <span>{{ model.modelName }} </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="col-lg-10 col-md-7 order-2 order-lg-2 order-md-2">
              <!-- -->
              <div class="row" v-if="filteredItems.length > 0">
                <div class="col-lg-3 " v-for="VehicleDetails in filteredItems" :key="VehicleDetails.vehId"
                  style="margin-bottom: 15px">
                  <div>
                    <div class="">
                      <!--new-main-tile-->
                      <section>
                        <div class="" style="margin: 0px 6px">
                          <div class="">
                            <div class="carTileWrap shine-overlay">
                              <div class="shine"></div>
                              <div class="cwrapImage" style="position: relative">
                                <router-link @click="isCustomer ? null : gotoLogin()" :to="{
                                    name: routerPath,
                                    params: { url_key: VehicleDetails.aucName },
                                  }">
                                  <img :src="path + VehicleDetails.vehImage1"
                                    class="img-fluid img-responsive border5px" /></router-link>

                                <div class="topleft">
                                  <div>
                                    <CountDown :customstyle="'background-color: red;'"
                                      :deadline="VehicleDetails.aucExtDate"></CountDown>
                                  </div>
                                </div>


                                <div class="topright">
                                  <div style="display: flex">
                                    <!-- Wishlist -->
                                    <div class="bidlistDetailswrapwidth15">
                                      <div v-if="VehicleDetails.isWishlisted == 1">
                                        <div class="button-bidzzz active" @click="
                                            rmvfromWhishlist(VehicleDetails)
                                          ">
                                          <svg width="35px" height="25px" xmlns="http://www.w3.org/2000/svg">
                                            <g fill="none" fill-rule="evenodd">
                                              <path class="heart-stroke"
                                                d="M13.0185191,4.25291223 L12.9746137,4.25291223 C10.1097846,4.25291223 8.67188189,6.6128289 8.5182129,8.92335198 C8.39747298,10.6740809 8.73225185,12.8528876 14.0777375,18.4782704 C14.7127154,19.1080239 15.5654911,19.4695694 16.4596069,19.4880952 C17.3247917,19.4700909 18.1444718,19.0969678 18.7262246,18.4563177 C19.3189478,17.9074999 24.5052763,12.5894551 24.3570955,8.98921012 C24.2363556,6.42623084 22.123407,4.25291223 19.7525139,4.25291223 C18.5053576,4.22947431 17.3125171,4.76253118 16.4980242,5.70727948 C15.6177331,4.73767759 14.354699,4.20555668 13.04596,4.25291223 L13.0185191,4.25291223 Z"
                                                fill="#0090E3" />
                                              <path class="heart-full"
                                                d="M13.0185191,4.25291223 L12.9746137,4.25291223 C10.1097846,4.25291223 8.67188189,6.6128289 8.5182129,8.92335198 C8.39747298,10.6740809 8.73225185,12.8528876 14.0777375,18.4782704 C14.7127154,19.1080239 15.5654911,19.4695694 16.4596069,19.4880952 C17.3247917,19.4700909 18.1444718,19.0969678 18.7262246,18.4563177 C19.3189478,17.9074999 24.5052763,12.5894551 24.3570955,8.98921012 C24.2363556,6.42623084 22.123407,4.25291223 19.7525139,4.25291223 C18.5053576,4.22947431 17.3125171,4.76253118 16.4980242,5.70727948 C15.6177331,4.73767759 14.354699,4.20555668 13.04596,4.25291223 L13.0185191,4.25291223 Z"
                                                fill="red" />
                                              <path class="heart-lines" d="M26,4 L30.6852129,0.251829715" stroke="red"
                                                stroke-width="2" stroke-linecap="round" />
                                              <path class="heart-lines" d="M2.314788,4 L7.00000086,0.251829715"
                                                stroke="red" stroke-width="2" stroke-linecap="round"
                                                transform="matrix(-1 0 0 1 10.314788 1)" />
                                              <path class="heart-lines" d="M27,12 L33,12" stroke="red" stroke-width="2"
                                                stroke-linecap="round" />
                                              <path class="heart-lines" d="M0,12 L6,12" stroke="red" stroke-width="2"
                                                stroke-linecap="round" transform="matrix(-1 0 0 1 7 1)" />
                                              <path class="heart-lines" d="M24,19 L28.6852129,22.7481703" stroke="red"
                                                stroke-width="2" stroke-linecap="round" />
                                              <path style="background:red" class="heart-lines"
                                                d="M4.314788,19 L9.00000086,22.7481703" stroke="red" stroke-width="2"
                                                stroke-linecap="round" transform="matrix(-1 0 0 1 14.314788 1)" />
                                            </g>
                                          </svg>
                                        </div>
                                      </div>
                                      <div v-else-if="
                                          VehicleDetails.isWishlisted == 0
                                        ">
                                        <div class="button-bidzzz" @click="addorRemove(VehicleDetails)">
                                          <svg width="35px" height="25px" xmlns="http://www.w3.org/2000/svg">
                                            <g fill="none" fill-rule="evenodd">
                                              <path class="heart-stroke"
                                                d="M13.0185191,4.25291223 L12.9746137,4.25291223 C10.1097846,4.25291223 8.67188189,6.6128289 8.5182129,8.92335198 C8.39747298,10.6740809 8.73225185,12.8528876 14.0777375,18.4782704 C14.7127154,19.1080239 15.5654911,19.4695694 16.4596069,19.4880952 C17.3247917,19.4700909 18.1444718,19.0969678 18.7262246,18.4563177 C19.3189478,17.9074999 24.5052763,12.5894551 24.3570955,8.98921012 C24.2363556,6.42623084 22.123407,4.25291223 19.7525139,4.25291223 C18.5053576,4.22947431 17.3125171,4.76253118 16.4980242,5.70727948 C15.6177331,4.73767759 14.354699,4.20555668 13.04596,4.25291223 L13.0185191,4.25291223 Z"
                                                fill="#0090E3" />
                                              <path class="heart-full"
                                                d="M13.0185191,4.25291223 L12.9746137,4.25291223 C10.1097846,4.25291223 8.67188189,6.6128289 8.5182129,8.92335198 C8.39747298,10.6740809 8.73225185,12.8528876 14.0777375,18.4782704 C14.7127154,19.1080239 15.5654911,19.4695694 16.4596069,19.4880952 C17.3247917,19.4700909 18.1444718,19.0969678 18.7262246,18.4563177 C19.3189478,17.9074999 24.5052763,12.5894551 24.3570955,8.98921012 C24.2363556,6.42623084 22.123407,4.25291223 19.7525139,4.25291223 C18.5053576,4.22947431 17.3125171,4.76253118 16.4980242,5.70727948 C15.6177331,4.73767759 14.354699,4.20555668 13.04596,4.25291223 L13.0185191,4.25291223 Z"
                                                fill="red" />
                                              <path class="heart-lines" d="M26,4 L30.6852129,0.251829715" stroke="red"
                                                stroke-width="2" stroke-linecap="round" />
                                              <path class="heart-lines" d="M2.314788,4 L7.00000086,0.251829715"
                                                stroke="red" stroke-width="2" stroke-linecap="round"
                                                transform="matrix(-1 0 0 1 10.314788 1)" />
                                              <path class="heart-lines" d="M27,12 L33,12" stroke="red" stroke-width="2"
                                                stroke-linecap="round" />
                                              <path class="heart-lines" d="M0,12 L6,12" stroke="red" stroke-width="2"
                                                stroke-linecap="round" transform="matrix(-1 0 0 1 7 1)" />
                                              <path class="heart-lines" d="M24,19 L28.6852129,22.7481703" stroke="red"
                                                stroke-width="2" stroke-linecap="round" />
                                              <path class="heart-lines" d="M4.314788,19 L9.00000086,22.7481703"
                                                stroke="red" stroke-width="2" stroke-linecap="round"
                                                transform="matrix(-1 0 0 1 14.314788 1)" />
                                            </g>
                                          </svg>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div>
                                <div class="vehicleName">
                                  <router-link @click="isCustomer ? null : gotoLogin()" :to="{
                                      name: routerPath,
                                      params: {
                                        url_key: VehicleDetails.aucName,
                                      },
                                    }">
                                    <h5>
                                      {{ VehicleDetails.brandName }}
                                      {{ VehicleDetails.modelName }}
                                      {{ VehicleDetails.manYear }}
                                    </h5>
                                  </router-link>
                                </div>

                                <div>
                                  <hr style="margin: 0px" />
                                  <div class="row">
                                    <div class="col-12">
                                      <div class="text-center">
                                        <div>
                                          <p class="tile-small-txt">
                                            CURRENT BID PRICE
                                          </p>
                                          <h4 style="
                                              font-size: 18px;
                                              font-weight: 600;
                                            ">
                                            {{
                                            $n(
                                            VehicleDetails.latestBidAmount,
                                            "currency",
                                            language
                                            )
                                            }}
                                          </h4>
                                          <button v-if="custId" :disabled="Buttondisabled" class="tile-bid-now"
                                            tabindex="-1" @click="showModal(VehicleDetails)">
                                            BID NOW
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                    <!-- <div class="col-6">
                                  <div class="text-center"></div>






                                </div> -->
                                  </div>

                                  <div></div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <!-- .container -->
                      </section>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else class="no-auctions-message">
                <NotFound></NotFound>
              </div>
              <!-- modal -->
              <div class="overlay-bid-btn" v-if="openModel && currentUser">
                <button class="close-bid-btn bid-popup-close" @click="closeModal()">
                  x
                </button>
                <h4>Bid Information</h4>
                <p>
                  Latest Bid Amount : {{ $n(amount, "currency", language) }}
                </p>
                <div class="auct-bid-pop-left-wrap">
                  <div class="auct-bid-pop-left">
                    <button class="btn btn-secondary" :class="{ buttonDisabled: isSubmitButtonDisabled }"
                      :disabled="isSubmitButtonDisabled" @click="decreaseBidAmount()">
                      <span>-</span>
                    </button>
                    <div class="auct-bid-pop-left-inner">
                      <input type="number" v-model="bidAmount" class="form-control" style="height: 37px" />
                    </div>
                    <button class="btn btn-secondary" @click="increaseBidAmount()">
                      <span>+</span>
                    </button>
                  </div>
                  <button class="bid-info-sub" :class="{ buttonDisabled: isSubmitButtonDisabled }" @click="SubmitBid()"
                    :disabled="isSubmitButtonDisabled">
                    Submit
                  </button>
                </div>
              </div>

              <div class="row" style="position: absolute; bottom: 0;right:50%">
                <div class="col-lg-12">
                  <div class="uren-paginatoin-area">
                    <div class="row">
                      <div class="col-lg-12">
                        <PageNation v-if="filteredItems.length > 0" :vechiclePage="AuctionsDetails"
                          @pageChange="PageRout">
                        </PageNation>


                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="tab-pane" id="tabs-2" role="tabpanel">
      <div class="" style="padding: 40px 0px; background-color:white; ">
        <div class="container-fluid">
          <div v-if="!upComingAuctionDetails.length">
            <NotFound></NotFound>
          </div>

          <div class="row" v-else>
            <div class="col-lg-2 col-md-5 order-1 order-lg-1 order-md-1">
              <div class="uren-sidebar-catagories_area" style="margin-top: 5px">
                <div class="uren-sidebar_categories">
                  <ul class="sidebar-checkbox_list">
                    <li>
                      <div class="clearAllFilter">
                        <h5>Filters</h5>
                        <a @click="ClearFilterdItemsList">Reset all</a>
                      </div>
                    </li>
                  </ul>
                </div>
                <div class="uren-sidebar_categories">
                  <div class="uren-categories_title">
                    <h5 @click="YearActive">
                      Year of Make
                      <span v-if="isYearActive"><i class="fa fa-chevron-down" aria-hidden="true"></i>
                      </span>
                      <span v-if="!isYearActive"><i class="fa fa-chevron-up" aria-hidden="true"></i>
                      </span>
                    </h5>
                  </div>

                  <div class="yearOfMakeClider" v-if="isYearActive">
                    <Slider v-model="yearOfMakeSlider.value" v-bind="yearOfMakeSlider" :min="yearOfMakeSlider.min"
                      :max="yearOfMakeSlider.max" @update="yearChange(yearOfMakeSlider)"></Slider>
                  </div>
                </div>
                <div class="uren-sidebar_categories">
                  <div class="uren-categories_title">
                    <h5 @click="KmActive">
                      KM
                      <span v-if="isKmActive"><i class="fa fa-chevron-down" aria-hidden="true"></i>
                      </span>
                      <span v-if="!isKmActive"><i class="fa fa-chevron-up" aria-hidden="true"></i>
                      </span>
                    </h5>
                  </div>
                  <div class="kmClockedSlider" v-if="isKmActive">
                    <Slider v-model="KmClockedSlider.value" v-bind="KmClockedSlider" :min="KmClockedSlider.min"
                      :max="KmClockedSlider.max" @update="kmClockedChange(KmClockedSlider)"></Slider>
                  </div>
                </div>
                <div class="uren-sidebar_categories">
                  <div class="uren-categories_title">
                    <h5 @click="BodyTypeActive">
                      Body Type
                      <span v-if="isBodyTypeActive"><i class="fa fa-chevron-down" aria-hidden="true"></i>
                      </span>
                      <span v-if="!isBodyTypeActive"><i class="fa fa-chevron-up" aria-hidden="true"></i>
                      </span>
                    </h5>
                  </div>
                  <input v-if="isBodyTypeActive" type="checkbox" id="isBodyTypeselectAll" class="isBodyTypeselectAll"
                    name="isBodyType" aria-label="All Body Type" @change="selectIndCheckBox('isBodyType')" />
                  <label for="" v-if="isBodyTypeActive">All Body Type</label>
                  <ul class="sidebar-checkbox_list" v-for="(TypeName, index) in uniqueVehType" :key="index">
                    <li v-if="isBodyTypeActive">
                      <input type="checkbox" name="chk" class="isBodyType" v-model="veTypeName"
                        :value="TypeName.vehTypeName" />

                      <span>{{ TypeName.vehTypeName }} </span>
                    </li>
                  </ul>
                </div>
                <div class="uren-sidebar_categories">
                  <div class="uren-categories_title">
                    <h5 @click="MakeActive">
                      Makes
                      <span v-if="isMakeActive"><i class="fa fa-chevron-down" aria-hidden="true"></i>
                      </span>
                      <span v-if="!isMakeActive"><i class="fa fa-chevron-up" aria-hidden="true"></i>
                      </span>
                    </h5>
                  </div>
                  <input v-if="isMakeActive" type="checkbox" id="isMakeselectAll" class="isMakeselectAll" name="isMake"
                    aria-label="Select All" @change="selectIndCheckBox('isMake')" />
                  <label for="" v-if="isMakeActive">All Makes </label>
                  <ul class="sidebar-checkbox_list" v-for="(brand, index) in uniqueBrand" :key="index">
                    <li v-if="isMakeActive">
                      <input type="checkbox" name="chk" class="isMake" v-model="veBrand" :value="brand.brandName" />
                      <span>{{ brand.brandName }} </span>
                    </li>
                  </ul>
                </div>
                <div class="uren-sidebar_categories">
                  <div class="uren-categories_title">
                    <h5 @click="ModelActive">
                      Model
                      <span v-if="isModelActive"><i class="fa fa-chevron-down" aria-hidden="true"></i>
                      </span>
                      <span v-if="!isModelActive"><i class="fa fa-chevron-up" aria-hidden="true"></i>
                      </span>
                    </h5>
                  </div>
                  <input v-if="isModelActive" type="checkbox" id="isModelselectAll" class="isModelselectAll"
                    name="isModel" aria-label="Select All" @change="selectIndCheckBox('isModel')" />
                  <label for="" v-if="isModelActive">All Models</label>
                  <ul class="sidebar-checkbox_list" v-for="(model, index) in uniqueModel" :key="index">
                    <li v-if="isModelActive">
                      <input type="checkbox" name="chk" class="isModel" v-model="veModel" :value="model.modelName" />
                      <span>{{ model.modelName }} </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div class="col-lg-10 col-md-7 order-2 order-lg-2 order-md-2">
              <div class="row" style="">
                <div class="col-lg-3 " v-for="data in upComingAuctionDetails" :key="data.vehId">
                  <section class="upComingBox">

                    <img :src="path + data.vehImage1" class="img-fluid img-responsive border5px" />

                    <div class="vehicleName">
                      <router-link @click="isCustomer ? null : gotoLogin()" :to="{
                                      name: routerPath,
                                      params: {
                                        url_key: data.aucName,
                                      },
                                    }">
                        <h5>

                          {{ data.brandName }}
                          {{ data.modelName }}
                          {{ data.manYear }}
                        </h5>
                      </router-link>
                    </div>

                    <hr style="margin: 0px" />
                    <div>
                      <hr style="margin: 0px" />
                      <div class="row">
                        <div class="col-12">
                          <div class="text-center" style="margin-top:2px">
                            <div class="topleft">
                              <div style="margin-top:10px">
                                <CountDown :customstyle="'background-color: red;'" :deadline="data.aucDate"></CountDown>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>








              <!-- -->
              <div class="row" v-if="upComingAuctionDetails.length > 0">


                <div class="col-lg-3" v-for="VehicleDetails in filteredItems" :key="VehicleDetails.vehId"
                  style="margin-top: 30px">

                </div>


              </div>
              <div v-else class="no-auctions-message">
                <NotFound></NotFound>
              </div>
              <!-- modal -->
              <div class="overlay-bid-btn" v-if="openModel && currentUser">
                <button class="close-bid-btn bid-popup-close" @click="closeModal()">
                  x
                </button>
                <h4>Bid Information</h4>
                <p>
                  Latest Bid Amount : {{ $n(amount, "currency", language) }}
                </p>
                <div class="auct-bid-pop-left-wrap">
                  <div class="auct-bid-pop-left">
                    <button class="btn btn-secondary" :class="{ buttonDisabled: isSubmitButtonDisabled }"
                      :disabled="isSubmitButtonDisabled" @click="decreaseBidAmount()">
                      <span>-</span>
                    </button>
                    <div class="auct-bid-pop-left-inner">
                      <input type="number" v-model="bidAmount" class="form-control" style="height: 37px" />
                    </div>
                    <button class="btn btn-secondary" @click="increaseBidAmount()">
                      <span>+</span>
                    </button>
                  </div>
                  <button class="bid-info-sub" :class="{ buttonDisabled: isSubmitButtonDisabled }" @click="SubmitBid()"
                    :disabled="isSubmitButtonDisabled">
                    Submit
                  </button>
                </div>
              </div>

              <div class="row" style="position: absolute; bottom: 0;right:50%">
              <div class="col-lg-12">
                <div class="uren-paginatoin-area">
                  <div class="row">
                    <div class="col-lg-12">
                      <PageNation v-if="filteredItems.length > 0" :vechiclePage="AuctionsDetails"
                        @pageChange="PageRout">
                      </PageNation>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
  </div>

  <navbar></navbar>
</template>

<script>
  import navbar from "@/components/navbar.vue";
  import Slider from "@vueform/slider";
  import NotFound from "@/components/modules/NotFound.vue";
  import userService from "@/services/user.service";
  import PageNation from "../modules/PageNation.vue";
  import CountDown from "../modules/CountDown.vue";
  import Config from "@/config.js";

  export default {
    name: "Auctions",
    components: {
      CountDown,
      PageNation,
      NotFound,
      Slider,
      navbar,
    },
    data() {
      return {
        path: Config.BASE_URL_IMG,
        openModel: false,
        amount: "",
        AuctionsDetails: [],
        userinfo: "",
        custId: "",
        bidAmount: "",
        Buttondisabled: false,
        selectedAuction: "",
        message: "",
        routerPath: "",
        perPage: Config.itemPerPageAuction,
        currentPage: 1,
        items: [],
        itemsInitialData: [],
        url_key: "",
        isLocationActive: false,
        isBodyTypeActive: false,
        isMakeActive: false,
        isYearActive: false,
        isModelActive: false,
        isKmActive: false,
        filtersAppied: [],
        veBrand: [],
        veModel: [],
        veLocation: [],
        veKmclocked: [],
        veYearofmake: [],
        veTypeName: [],
        language: Config.BASE_LANG,
        yrofmakeMin: null,
        yrofmakeMax: null,
        kmClockedMin: null,
        kmClockedMax: null,
        minIceament: "",

        upComingAuctionDetails: [],
      };
    },
    UpcomingAuctionsData() { },

    computed: {
      loggedIn() {
        return this.$store.state.auth.status.loggedIn;
      },
      isCustomer() {
        let userInfo = this.$store.state.auth.user;
        let is_Customer = false;

        if (userInfo && "customerId" in userInfo) {
          is_Customer = true;
        }
        return is_Customer;
      },
      currentUser() {
        return this.$store.state.auth.user;
      },
      isSubmitButtonDisabled() {
        return this.bidAmount <= this.amount;
      },

      filteredItems() {
        console.log(
          ">>>>>>>>>>>>>>upComingAuctionDetails",
          this.upComingAuctionDetails
        );

        return this.items.filter((item) => {
          return (
            // (this.keyword.length === 0 || item.name.includes(this.keyword)) &&
            (this.veModel.length === 0 ||
              this.veModel.includes(item.modelName)) &&
            (this.veTypeName.length === 0 ||
              this.veTypeName.includes(item.vehTypeName)) &&
            (this.veBrand.length === 0 ||
              this.veBrand.includes(item.brandName)) &&
            (this.veLocation.length === 0 ||
              this.veLocation.includes(item.locName)) &&
            parseInt(this.kmClockedMin) < parseInt(item.kmClocked) &&
            parseInt(this.kmClockedMax) > parseInt(item.kmClocked) &&
            parseInt(this.yrofmakeMin) < parseInt(item.manYear) &&
            parseInt(this.yrofmakeMax) > parseInt(item.manYear)
          );
        });
        // .sort((a, b) => {
        //   console.log(this.sortBy);
        //   return a[this.sortBy]
        //     .toString()
        //     .localeCompare(b[this.sortBy].toString());
        // });
      },

      filterdUpcomingData() {
        //console.log("upComingAuctionDetailssss",this.upComingAuctionDetails)

        console.log("filteredItems", this.filteredItems());

        return this.upComingAuctionDetails.filter((item) => {
          return (
            // (this.keyword.length === 0 || item.name.includes(this.keyword)) &&
            (this.veModel.length === 0 ||
              this.veModel.includes(item.modelName)) &&
            (this.veTypeName.length === 0 ||
              this.veTypeName.includes(item.vehTypeName)) &&
            (this.veBrand.length === 0 ||
              this.veBrand.includes(item.brandName)) &&
            (this.veLocation.length === 0 ||
              this.veLocation.includes(item.locName)) &&
            parseInt(this.kmClockedMin) < parseInt(item.kmClocked) &&
            parseInt(this.kmClockedMax) > parseInt(item.kmClocked) &&
            parseInt(this.yrofmakeMin) < parseInt(item.manYear) &&
            parseInt(this.yrofmakeMax) > parseInt(item.manYear)
          );
        });
        // .sort((a, b) => {
        //   console.log(this.sortBy);
        //   return a[this.sortBy]
        //     .toString()
        //     .localeCompare(b[this.sortBy].toString());
        // });
      },

      KmClockedSlider() {
        var kmCloc = {
          value: [this.items[0].minkmClocked, this.items[0].maxkmClocked],
          min: this.items[0].minkmClocked,
          max: this.items[0].maxkmClocked,
        };
        return kmCloc;
      },
      yearOfMakeSlider() {
        var years = {
          value: [this.items[0].minManYear, this.items[0].maxManYear],
          min: this.items[0].minManYear,
          max: this.items[0].maxManYear,
        };
        return years;
      },

      uniqueBrand() {
        return this.items.reduce((seed, current) => {
          return Object.assign(seed, {
            [current.brandName]: current,
          });
        }, {});
      },
      uniqueModel() {
        return this.items.reduce((seed, current) => {
          return Object.assign(seed, {
            [current.modelName]: current,
          });
        }, {});
      },
      uniqueLocation() {
        return this.items.reduce((seed, current) => {
          return Object.assign(seed, {
            [current.locName]: current,
          });
        }, {});
      },
      uniqueKmClocked() {
        console.log("1");
        return this.items.reduce((seed, current) => {
          return Object.assign(seed, {
            [current.kmClocked]: current,
          });
        }, {});
      },
      uniqueYearOfMake() {
        return this.items.reduce((seed, current) => {
          return Object.assign(seed, {
            [current.manYear]: current,
          });
        }, {});
      },
      uniqueVehType() {
        return this.items.reduce((seed, current) => {
          return Object.assign(seed, {
            [current.vehTypeName]: current,
          });
        }, {});
      },
    },
    watch: {
      "$route.query.search"() {
        this.url_key = this.$route.query.search;
        this.getAuctionsList();
        this.getUpcoming();
      },
    },
    created() {
      if (this.loggedIn) {
        if (this.currentUser != null) {
          if (typeof this.currentUser.customerId != "undefined") {
            this.custId = this.currentUser.customerId;

            this.routerPath = "AuctionDetails";
            this.getAuctionsList();
            this.getUpcoming();
          } else {
            this.routerPath = "";
          }
        }
      } else {
        this.getAuctionsList();
        this.getUpcoming();
      }
    },
    mounted() {
      if (this.custId) {
        this.Buttondisabled = false;
      } else {
        this.Buttondisabled = true;
      }
    },

    methods: {
      // minIceament
      //getUpcomingAuctionDetails

      decreaseBidAmount() {
        if (this.bidAmount >= this.amount) {
          this.bidAmount -= this.minIceament;
        }
      },
      increaseBidAmount() {
        this.bidAmount += this.minIceament;
      },
      gotoLogin() {
        this.$toast.warning("Please  sign in to  continue !", {
          position: "top",
          duration: 3000,
          queue: true,
        });
      },

      selectIndCheckBox(selected) {
        // isBodyTypeselectAll
        if (selected === "isBodyType") {
          var allTypeElement = document.getElementsByClassName(
            "isBodyTypeselectAll"
          );
          var eleme = document.getElementsByClassName("isBodyType");

          for (var k = 0; k < allTypeElement.length; k++) {
            if (allTypeElement[k].checked) {
              for (let index = 0; index < eleme.length; index++) {
                eleme[index].checked = true;
              }
              this.veTypeName = [];

              // Updating veBrand with uniqueBrand
              Object.keys(this.uniqueVehType).forEach((key) => {
                this.veTypeName.push(key);
              });
            } else {
              for (let index = 0; index < eleme.length; index++) {
                eleme[index].checked = false;
              }
              // When All Makes checkbox is unchecked
              this.veTypeName = [];
            }
          }
        }

        if (selected === "isMake") {
          var allMakeElement = document.getElementsByClassName("isMakeselectAll");
          var ele = document.getElementsByClassName("isMake");

          for (var i = 0; i < allMakeElement.length; i++) {
            if (allMakeElement[i].checked) {
              for (let index = 0; index < ele.length; index++) {
                ele[index].checked = true;
              }
              this.veBrand = [];

              // Updating veBrand with uniqueBrand
              Object.keys(this.uniqueBrand).forEach((key) => {
                this.veBrand.push(key);
              });
            } else {
              for (let index = 0; index < ele.length; index++) {
                ele[index].checked = false;
              }
              // When All Makes checkbox is unchecked
              this.veBrand = [];
            }
          }
        }
        if (selected === "isModel") {
          var allModelElement =
            document.getElementsByClassName("isModelselectAll");
          var elem = document.getElementsByClassName("isModel");

          for (var j = 0; j < allModelElement.length; j++) {
            if (allModelElement[j].checked) {
              for (let index = 0; index < elem.length; index++) {
                elem[index].checked = true;
              }
              this.veModel = [];

              // Updating veBrand with uniqueBrand
              Object.keys(this.uniqueModel).forEach((key) => {
                this.veModel.push(key);
              });
            } else {
              for (let index = 0; index < elem.length; index++) {
                elem[index].checked = false;
              }
              // When All Makes checkbox is unchecked
              this.veModel = [];
            }
          }
        }
      },

      kmClockedChange(KmClockedS) {
        this.kmClockedMin = KmClockedS.value[0];
        this.kmClockedMax = KmClockedS.value[1];
      },
      yearChange(yearChanged) {
        this.yrofmakeMin = yearChanged.value[0];
        this.yrofmakeMax = yearChanged.value[1];
      },
      ClearFilterdItemsList() {
        this.veBrand = [];
        this.veModel = [];

        this.veKmclocked = [];
        this.veYearofmake = [];
        this.$route.query.search = null;
        this.$router.push({ path: "/auction" });
        setTimeout(() => {
          location.reload();
        }, 1000);
      },

      LocationActive() {
        this.isLocationActive = !this.isLocationActive;
      },
      BodyTypeActive() {
        this.isBodyTypeActive = !this.isBodyTypeActive;
      },
      MakeActive() {
        this.isMakeActive = !this.isMakeActive;
        //this.selectIndCheckBox("isMake");
      },
      YearActive() {
        this.isYearActive = !this.isYearActive;
      },
      ModelActive() {
        this.isModelActive = !this.isModelActive;
      },
      KmActive() {
        this.isKmActive = !this.isKmActive;
      },
      PageRout(userData) {
        // console.log(userData);
        this.items = userData;
      },

      addorRemove(elem) {
        if (this.currentUser) {
          this.addWhishlist(elem);
        } else {
          this.$router.push("/").catch(() => {
            "carlist";
          });
        }
      },
      addWhishlist(elem) {
        userService.addToWhishlist(this.custId, elem).then((response) => {
          console.log(response.data.Message);
          this.getAuctionsList();
          this.getUpcoming();
        });
      },
      rmvfromWhishlist(item) {
        userService.rmvfromWhishlist(item, this.custId).then((response) => {
          console.log(response.data.Message);
          this.getAuctionsList();
          this.getUpcoming();
        });
      },
      showModal(e) {
        this.openModel = !this.openModel;
        this.amount = e.latestBidAmount;
        this.minIceament = e.aucMinBid;
        this.bidAmount = e.latestBidAmount + this.minIceament;
        this.selectedAuction = e;
      },
      closeModal() {
        var self = this;
        setTimeout(function () {
          self.hideModal();
        }, 3000);
      },
      hideModal() {
        this.openModel = false;
        this.message = "";
      },
      SubmitBid() {
        this.message = "";
        if (this.custId && this.bidAmount !== "" && this.bidAmount !== null) {
          if (!this.isMultipleOfHundred(this.bidAmount)) {
            this.$toast.warning("Please enter a multiple of 100", {
              position: "top",
              duration: 3000,
              queue: true,
            });
          } else {
            let cusid = this.custId;
            let bidamount = parseInt(this.bidAmount);

            userService
              .addbid(this.selectedAuction, cusid, bidamount)
              .then((response) => {
                this.message = response.data.Message;
                this.message = "";
                this.$toast.success(" Suceesfully added bid", {
                  position: "top",
                  duration: 3000,
                  queue: true,
                });

                this.closeModal();
                this.getAuctionsList();
                this.getUpcoming();
              })
              .catch((error) => {
                this.message =
                  (error.response && error.response.data.Message) ||
                  error.message ||
                  error.toString();
                this.message = "";
                this.$toast.error(error.response.data.Message, {
                  position: "top",
                  duration: 3000,
                  queue: true,
                });
              });
          }
        } else {
          this.$toast.error("Please enter a valid bid amount", {
            position: "top",
            duration: 3000,
            queue: true,
          });
        }
      },
      isMultipleOfHundred(number) {
        return number % this.minIceament === 0;
      },

      getAuctionsList() {
        this.url_key = this.$route.query.search;
        let modelNameHm = this.$route.params.url_key;
        //  console.log("modelNameHm : ",modelNameHm);
        // console.log("this.url_key : " ,this.url_key );

        if (this.url_key != "" && this.url_key != null) {
          userService
            .getAuctionListSearch(
              this.customerId,
              this.url_key.replace(/\s/g, "")
            )
            .then((resp) => {
              this.AuctionsDetails = resp.data.Data;

              this.items = this.AuctionsDetails.slice(
                (this.currentPage - 1) * this.perPage,
                this.currentPage * this.perPage
              );

              if (this.AuctionsDetails.length !== 0) {
                this.kmClockedMin = this.AuctionsDetails[0].minkmClocked;
                this.kmClockedMax = this.AuctionsDetails[0].maxkmClocked;
                this.yrofmakeMin = this.AuctionsDetails[0].minManYear;
                this.yrofmakeMax = this.AuctionsDetails[0].maxManYear;
              }
            })
            .catch((err) => {
              console.log(err.response);
            });
        } else {
          userService
            //.getUpcomingAuctionsData(this.custId)
            .getvehbyId(this.custId)
            .then((response) => {
              let vehList = response.data.Data;
              console.log("?vehList", vehList);
              if (modelNameHm) {
                var newArray = vehList.filter(function (el) {
                  return el.brandName.includes(modelNameHm);
                });

                this.AuctionsDetails = newArray;
              } else {
                this.AuctionsDetails = response.data.Data;
              }

              this.items = this.AuctionsDetails.slice(
                (this.currentPage - 1) * this.perPage,
                this.currentPage * this.perPage
              );
              if (this.AuctionsDetails.length !== 0) {
                this.kmClockedMin = this.AuctionsDetails[0].minkmClocked;
                this.kmClockedMax = this.AuctionsDetails[0].maxkmClocked;
                this.yrofmakeMin = this.AuctionsDetails[0].minManYear;
                this.yrofmakeMax = this.AuctionsDetails[0].maxManYear;
              }
            })
            .catch((e) => {
              console.log("/////////////////////////////////", e);
            });
        }
      },

      getUpcoming() {
        this.url_key = this.$route.query.search;
        let modelNameHm = this.$route.params.url_key;
        //  console.log("modelNameHm : ",modelNameHm);
        // console.log("this.url_key : " ,this.url_key );

        if (this.url_key != "" && this.url_key != null) {
          userService
            .getAuctionListSearch(
              this.customerId,
              this.url_key.replace(/\s/g, "")
            )
            .then((resp) => {
              this.AuctionsDetails = resp.data.Data;

              this.items = this.AuctionsDetails.slice(
                (this.currentPage - 1) * this.perPage,
                this.currentPage * this.perPage
              );

              if (this.AuctionsDetails.length !== 0) {
                this.kmClockedMin = this.AuctionsDetails[0].minkmClocked;
                this.kmClockedMax = this.AuctionsDetails[0].maxkmClocked;
                this.yrofmakeMin = this.AuctionsDetails[0].minManYear;
                this.yrofmakeMax = this.AuctionsDetails[0].maxManYear;
              }
            })
            .catch((err) => {
              console.log(err.response);
            });
        } else {
          userService
            .getUpcomingAuctionsData()
            //.getvehbyId(this.custId)
            .then((response) => {
              let vehList = response.data.Data;
              //console.log("?vehListtttttttttttttttttttttttttt",vehList)
              if (modelNameHm) {
                var newArray = vehList.filter(function (el) {
                  return el.brandName.includes(modelNameHm);
                });

                this.AuctionsDetails = newArray;
              } else {
                this.AuctionsDetails = response.data.Data;
              }

              this.upComingAuctionDetails = this.AuctionsDetails.slice(
                (this.currentPage - 1) * this.perPage,
                this.currentPage * this.perPage
              );
              if (this.AuctionsDetails.length !== 0) {
                this.kmClockedMin = this.AuctionsDetails[0].minkmClocked;
                this.kmClockedMax = this.AuctionsDetails[0].maxkmClocked;
                this.yrofmakeMin = this.AuctionsDetails[0].minManYear;
                this.yrofmakeMax = this.AuctionsDetails[0].maxManYear;
              }
            })
            .catch((e) => {
              console.log("/////////////////////////////////", e);
            });
        }
      },
    },
  };
</script>

<style src="@vueform/slider/themes/default.css"></style>

<style scoped>
  .Applybutton {
    background-color: white;
    color: black;
    border: 2px solid #4ca3af;
    /* Green */
    margin-right: 2%;
    width: 30%;
  }

  .Clearbutton {
    background-color: white;
    color: black;
    border: 2px solid #f70d3f;
    /* red */
    margin-right: 2%;
    width: 30%;
    box-shadow: 0 8px 18px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
  }

  .Refreshbutton {
    background-color: white;
    color: black;
    border: 2px solid #f53513;
    /* red */
    margin-right: 2%;
    width: 30%;
  }

  .carTileWrap {
    padding: 5px;
    background-color: #f2f2f2 !important;
    border-radius: 5px;
    box-shadow: 0px 0px 4px #a2a2a2;
  }

  .uren-sidebar-catagories_area .uren-sidebar_categories {
    background-color: #ebeff5;
    padding: 15px;
    margin-bottom: 15px;
    border-radius: 3px;
  }

  .uren-sidebar-catagories_area .uren-sidebar_categories .uren-categories_title h5 {
    text-transform: uppercase;
    margin-bottom: 0;
    padding-bottom: 5px;
    color: black;
  }

  .uren-sidebar-catagories_area .uren-sidebar_categories .sidebar-checkbox_list>li>a {
    color: black;
  }

  .subBtnzz {
    background-image: linear-gradient(to bottom right, #1d1b2b, #004a6d);
    color: white;
    font-weight: 600;
  }

  .bidamountzz {
    font-size: 20px;
    font-weight: 600;
  }

  .heart-stroke {
    fill: none;
    stroke: #666;
    stroke-width: 2px;
    opacity: 1;
    transform-origin: center center;
  }

  .button-bidzzz.active .heart-stroke {
    opacity: 0;
  }

  .heart-full {
    opacity: 0;
    transform-origin: 50% 50%;
  }

  .button-bidzzz.active .heart-full {
    opacity: 1;
  }

  .heart-lines {
    stroke-width: 2px;
    display: none;
  }

  .button-bidzzz:not(.active):hover .heart-stroke {
    -webkit-animation: pulse 1s ease-out infinite;
    animation: pulse 1s ease-out infinite;
  }

  .button-bidzzz.animate .heart-full {
    -webkit-animation: heart 0.35s;
    animation: heart 0.35s;
  }

  .button-bidzzz.animate .heart-lines {
    -webkit-animation: lines 0.2s ease-out forwards;
    animation: lines 0.2s ease-out forwards;
    display: block;
  }

  @-webkit-keyframes lines {
    0% {
      stroke-dasharray: 6;
      stroke-dashoffset: 16;
    }

    100% {
      stroke-dasharray: 13;
      stroke-dashoffset: 18;
    }
  }

  @keyframes lines {
    0% {
      stroke-dasharray: 6;
      stroke-dashoffset: 16;
    }

    100% {
      stroke-dasharray: 13;
      stroke-dashoffset: 18;
    }
  }

  @-webkit-keyframes heart {
    0% {
      transform: scale(1);
      transform-origin: center center;
      -webkit-animation-timing-function: ease-out;
      animation-timing-function: ease-out;
    }

    10% {
      transform: scale(1.2);
      -webkit-animation-timing-function: ease-in;
      animation-timing-function: ease-in;
    }

    35% {
      transform: scale(1);
      -webkit-animation-timing-function: ease-out;
      animation-timing-function: ease-out;
    }

    75% {
      transform: scale(1.1);
      -webkit-animation-timing-function: ease-in;
      animation-timing-function: ease-in;
    }

    100% {
      transform: scale(1);
      -webkit-animation-timing-function: ease-out;
      animation-timing-function: ease-out;
    }
  }

  @keyframes heart {
    0% {
      transform: scale(1);
      transform-origin: center center;
      -webkit-animation-timing-function: ease-out;
      animation-timing-function: ease-out;
    }

    10% {
      transform: scale(1.2);
      -webkit-animation-timing-function: ease-in;
      animation-timing-function: ease-in;
    }

    35% {
      transform: scale(1);
      -webkit-animation-timing-function: ease-out;
      animation-timing-function: ease-out;
    }

    75% {
      transform: scale(1.1);
      -webkit-animation-timing-function: ease-in;
      animation-timing-function: ease-in;
    }

    100% {
      transform: scale(1);
      -webkit-animation-timing-function: ease-out;
      animation-timing-function: ease-out;
    }
  }

  @-webkit-keyframes pulse {
    0% {
      opacity: 1;
      transform-origin: center center;
      transform: scale(1);
    }

    50% {
      opacity: 0.6;
      transform: scale(1.15);
    }

    100% {
      opacity: 1;
      transform: scale(1);
    }
  }

  @keyframes pulse {
    0% {
      opacity: 1;
      transform-origin: center center;
      transform: scale(1);
    }

    50% {
      opacity: 0.6;
      transform: scale(1.15);
    }

    100% {
      opacity: 1;
      transform: scale(1);
    }
  }

  .btn-1 {
    width: 80px;
    border: 0px solid #9d9d9d;
    border-radius: 2px;
    height: 30px;
    background: rgb(155 156 173);
    background: linear-gradient(0deg, rgb(0 40 104) 0%, rgb(0 62 161) 100%);
    color: white;
    font-weight: 500;
    font-size: 14px;
  }

  .btn-1:hover {
    background: rgb(0, 3, 255);
    background: linear-gradient(0deg,
        rgba(0, 3, 255, 1) 0%,
        rgba(2, 126, 251, 1) 100%);
  }

  .no-auctions-message {
    text-align: center;
    /* margin-top: 50%; */
  }

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    height: 100vh;
    /* Set the height of the container as needed */
  }

  .buttonDisabled {
    opacity: 0.2;
  }
</style>