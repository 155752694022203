<template>
  <div>
    <button v-if="currentPage > 1" @click="PrevPage" class="page-link">
      Prev
    </button>
    <button
      v-for="pageNumber in numberOfPages"
      :key="pageNumber"
      @click="currentPageNumber(pageNumber)"
      class="page-link"
    >
      {{ pageNumber }}
    </button>
    <button
      class="page-link"
      v-if="currentPage < numberOfPages"
      @click="nextPage"
    >
      Next
    </button>
  </div>
</template>
<script>
import Config from "@/config.js";
export default {
  props: {
    vechiclePage: Array,
  },
  data() {
    return {
      perPage:  Config.itemPerPageAuction,
      currentPage: 1,
    };
  },
  
  computed: {
    items() {
      return this.vechiclePage.slice(
        (this.currentPage - 1) * this.perPage,
        this.currentPage * this.perPage
      );
    },
    numberOfPages() {
      return Math.ceil(this.vechiclePage.length / this.perPage);
    },
    totalRows() {
      return this.vechiclePage.length;
    },
  },
  methods: {
    currentPageNumber(e) {
      this.currentPage = e;
      this.$emit("pageChange" ,this.items);
    },
    nextPage() {
      if (this.currentPage < this.numberOfPages) {
        this.currentPage++;
        this.$emit("pageChange" ,this.items);
      }
    },
    PrevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
        this.$emit("pageChange" ,this.items);
      }
    },
  },
};
</script>
<style scoped>
button.page-link {
  display: inline-block;
}
button.page-link {
  font-size: 20px;
  color: #29b3ed;
  font-weight: 500;
}

</style>
